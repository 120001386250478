import React from 'react';
import LayoutEnglish from '../../components/layout-en';

const About = () => (
  <LayoutEnglish>
    <section className="max-w-3xl mx-auto px-4 py-16">
      <h1 className="text-4xl font-extrabold text-teal-700 mb-4">
        ¿Quiénes somos?
      </h1>
      <p className="text-base md:text-xl text-gray-800 mb-4">
        Somos una empresa familiar que desea elaborar la madera de pino que
        producimos centrándonos en el equilibrio. Equilibrio con el medio
        ambiente, con las personas que nos ayudan a lograrlo y con la comunidad
        a la que pertenecemos.
      </p>
      <p className="text-base md:text-xl text-gray-800 mb-8">
        Aspiramos a la optima calidad de nuestros productos basándonos en la
        trazabilidad , en el manejo sustentable en todo el proceso de
        elaboración desde el origen (desde la plata) al producto final.
      </p>

      <h2 className="text-2xl font-semibold text-blue-800">¿Qué producimos?</h2>
      <p className="text-base md:text-xl text-gray-800 mb-8">
        Producimos cabos de escoba, tableros finger joint y diferentes molduras
        de madera de pino.
      </p>

      <h2 className="text-2xl font-semibold text-blue-800">Sustentabilidad</h2>
      <p className="text-base md:text-xl text-gray-800 mb-4">
        Los productos son realizados de manera compatible con los recursos que
        disponemos. La calidad de nuestros productos y servicios se apoyan en el
        bienestar de nuestros empleados y el origen de nuestros insumos,
        producidos en nuestros campos en la provincia de Corrientes.
      </p>

      {/* <p className="text-base md:text-xl text-gray-800 mb-8">
        El FSC (Forest Stewardship Council) garantiza que los productos tienen
        su origen en bosq ues bien manejados y que proporcionan beneficios
        ambientales , sociales y económicos. Es una norma de manejo forestal a
        nivel mundial que garantiza la sustentabilidad.
      </p> */}

      <h2 className="text-2xl font-semibold text-blue-800">Mercados</h2>
      <p className="text-base md:text-xl text-gray-800">
        Exportamos a EEUU y Europa. Local (Argentina).
      </p>
    </section>
  </LayoutEnglish>
);

export default About;
